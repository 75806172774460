import type { StoryblokContent } from '../storyblok'
import type { Asset } from './shared'
import type { TextBlockV2 } from './text-block-v2'

export interface TextMediaBlockV2 extends StoryblokContent {
  component: 'text-media-block-v2'
  smallAsset: Asset
  altTextSmall: string
  largeAsset: Asset
  altTextLarge: string
  assetPosition: 'top' | 'left' | 'right'
  overlay: Asset
  altTextOverlay: string
  textBlock: TextBlockV2[]
}

export function normalizeTextMediaBlockV2(blok: TextMediaBlockV2) {
  return {
    backgroundColor: blok?.textBlock?.[0]?.backgroundColor,
    fontAndCtaColor: blok?.textBlock?.[0]?.fontAndCtaColor,
    shortTitle:
      blok?.textBlock?.[0]?.title?.[0]?.component === 'shortTitle'
        ? blok.textBlock?.[0]?.title[0].text
        : undefined,
    longTitle:
      blok?.textBlock?.[0]?.title?.[0]?.component === 'longTitle'
        ? blok.textBlock?.[0]?.title[0].text
        : undefined,
    text: blok?.textBlock?.[0]?.text,
    label: blok?.textBlock?.[0]?.label,
    link: blok?.textBlock?.[0]?.link?.cached_url,
    ctaLabel: blok?.textBlock?.[0]?.ctaLabel,
    ctaLink: blok?.textBlock?.[0]?.ctaLink?.cached_url,
    smallAsset: blok?.smallAsset.filename,
    altTextSmall: blok?.altTextSmall,
    largeAsset: blok?.largeAsset.filename,
    altTextLarge: blok?.altTextLarge,
    isMediaTop: blok?.assetPosition === 'top',
    isMediaLeft: blok?.assetPosition === 'left',
    isMediaRight: blok?.assetPosition === 'right',
    overlay: blok?.overlay.filename,
    altTextOverlay: blok?.altTextOverlay,
    edgeStyle: blok?.textBlock?.[0]?.edgeStyle ?? 'none',
  }
}

